const pipe = x => () => import(`@/views/${x}`);
const layout = pipe('layout/index')

// 基本路由
export const defaultRouter = [
    {path: '/', component: layout, redirect: '/liveStream'},
    {path: '/login', name: 'login', component: pipe('login/index')}
]
// 项目页面路由
export const appRouter = [
    {
        path: '/liveStream',
        name: 'liveStream',
        redirect: '/liveStream/deviceManage',
        meta: { title: '管理后台', icon: '' },
        component: layout,
        children: [{
            path: 'deviceManage', name: 'deviceManage', component: pipe('liveStream/deviceManage'), meta: { title: '设备绑定管理' },
        }, {
            path: 'userManage', name: 'userManage', component: pipe('liveStream/userManage'), meta: { title: '超管管理' }
        }, {
            path: 'flowManage', name: 'flowManage', component: pipe('liveStream/flowManage'), meta: { title: '流量管理' }
        }, {
            path: 'videoBoxBind', name: 'videoBoxBind', component: pipe('liveStream/videoBoxBind'), meta: { title: '视频盒子绑定' }
        }]
    },
    // {
    //     path: '/liveStream',
    //     name: 'liveStream',
    //     redirect: '/liveStream/userManage',
    //     meta: { title: '超管管理', icon: '' },
    //     component: layout,
    //     children: [{
    //         path: 'userManage', name: 'userManage', component: pipe('liveStream/userManage'), meta: { title: '超管管理' }
    //     }]
    // },
   
]

// 所有上面定义的路由都要写在下面的routers里
export const routers = [
    ...defaultRouter,
    ...appRouter
  ]